import React from "react";
import "./App.scss";

function App() {
  return (
    <section className="App-body">
      <h1>Jonathan Skudlik</h1>
      <div className="social-media-bar">
        <a
          className="App-link"
          href="https://instagram.com/jonathanskudlik"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          className="App-link"
          href="https://vimeo.com/spielmannsbilder"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-vimeo-v"></i>
        </a>
        <a
          className="App-link"
          href="https://www.splmns.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-plus"></i>
        </a>
        {/* <a
className="App-link"
href="https://github.com/jsplmns"
target="_blank"
rel="noopener noreferrer"
>
<i className="fab fa-github"></i>
</a> */}
      </div>
    </section>
  );
}

export default App;
